import { memo } from "react";
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import Container from "./ContainerDragDrop";

export default memo(function Painel({style, width = 14, height = 14, boxes, setBoxes, editable = false}) {

    return(
        <div style={style}>
            <DndProvider backend={HTML5Backend} debugMode={true}>
                <Container height={height} width={width} boxes={boxes} setBoxes={setBoxes} editable={editable}/>
            </DndProvider>
        </div>
    );

});
