import update from 'immutability-helper'
import { useCallback, useState } from 'react'
import { useDrop } from 'react-dnd'
import { Box } from './Box.jsx'

function snapToGrid(x, y) {
    const snappedX = Math.round(x / 4) * 4
    const snappedY = Math.round(y / 4) * 4
    return [snappedX, snappedY]
}
  
export default function ContainerDragDrop({width, height, boxes, setBoxes, editable}) {
    
    const styles = {
        width: `${width}cm`,
        height: `${height}cm`,
        border: '1px solid black',
        position: 'relative'
    };

    const moveBox = useCallback(
        (id, left, top) => {
            setBoxes(
            update(boxes, {
                [id]: {
                $merge: { left, top },
                },
            }),
            )
        },
        [boxes],
    );

    const [, drop] = useDrop(
        () => ({
            accept: 'box',
            drop(item, monitor) {
            const delta = monitor.getDifferenceFromInitialOffset()
            let left = Math.round(item.left + delta.x)
            let top = Math.round(item.top + delta.y)
            if (snapToGrid) {
                ;[left, top] = snapToGrid(left, top)
            }
            moveBox(item.id, left, top)
            return undefined
            },
        }),
        [moveBox],
    );

    return (
        <div ref={editable ? drop : null} style={styles}>
            {Object.keys(boxes).map((key) => (
                <Box key={key} id={key} {...boxes[key]} />
            ))}
        </div>
    );

}


