import axios from "axios";
import localforage from "localforage";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

import global from "../utils/global";
import {ModalLoad, ModalErro} from "../components/models/models";

export default function Login (){

    const [userEmail, setUserEmail] = useState('');
    const [userSenha, setUserSenha] = useState('');

    //modal load
    const [showModalLoad, setShowModalLoad] = useState(false);

    //modal erro
    const [showModalErro, setShowModalErro] = useState(false);
    const [msgModalErro, setMsgModalErro] = useState('');
    const [funcPosModalErro, setFuncPosModalErro] = useState(() => () => {});

    //modal sucesso
    const [showModalSucesso, setShowModalSucesso] = useState(false);
    const [msgModalSucesso, setMsgModalSucesso] = useState('');
    const [funcPosModalSucesso, setFuncPosModalSucesso] = useState(() => () => {});

    const logar = () => {

        if(userEmail == null || userEmail == ''){
            setMsgModalErro('Preencha o campo de login!');
            setShowModalErro(true);
        }

        if(userSenha == null || userSenha == ''){
            setMsgModalErro('Preencha o campo de senha!');
            setShowModalErro(true);
        }

        setShowModalLoad(true);

        axios.post( global.baseUrl + '/AuthApi/LoginFuncionario', {
            login: userEmail,
            senha: userSenha
        })
        .then((response) => {
            
            if(response.data.code == 0){

                let user = response.data.content;
                localforage.setItem('user', user)
                .then(() => {
                    window.location.href = "/";     
                });
                
            }else{
                setMsgModalErro('Erro ao efetuar login: ' + response.data.msg);
                setShowModalErro(true);
            }

        })
        .catch((ex) => {

            console.log('erro: ', ex);

            setMsgModalErro('Erro ao efetuar login!');
            setShowModalErro(true);

        })
        .finally(() => setShowModalLoad(false));

    }

    return(
        <Container>
            <ModalErro
                showModal={showModalErro}
                setShowModal={setShowModalErro}
                msg={msgModalErro}
                funcPos={funcPosModalErro}
            />
            <ModalLoad showModal={showModalLoad} />

            <Row style={{marginRight: 10, marginLeft: 10, marginTop: 15, marginBottom: 15}}>
                <Col>
                    <h1 style={{textAlign: 'center'}}>Login Etiqueta</h1>
                </Col>
            </Row>
            <Row style={{marginRight: 10, marginLeft: 10, marginTop: 15, marginBottom: 15}}>
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                    type="text"
                    value={userEmail}
                    onChange={(event) => setUserEmail(event.target.value)}
                />
            </Row>
            <Row style={{marginRight: 10, marginLeft: 10, marginTop: 15, marginBottom: 15}}> 
                <Form.Label>Senha</Form.Label>
                <Form.Control
                    type="password"
                    value={userSenha}
                    onChange={(event) => setUserSenha(event.target.value)}
                />
            </Row>
            <Row style={{marginRight: 5, marginLeft: 5, marginTop: 15, marginBottom: 15}}>
                <Col>
                    <div className="d-grid gap-2">
                        <Button
                        variant="primary"
                        onClick={logar}
                        >Login</Button>{' '}
                    </div>
                </Col>
            </Row>
        </Container>
    );

};
