
import { useState } from "react";
import localforage from "localforage";
import { BrowserRouter, Route, Routes } from "react-router";

import Login from "./pages/Login";
import NavbarUser from "./components/Navbar";
import CadEdicaoMoldes from "./pages/Moldes/CadEdicaoMoldes";
import BuscarMoldes from "./pages/Moldes/BuscarMoldes";
import PrintEtiquetas from "./pages/ImprimirEtiquetas/printEtiquetas";

function App() {

  const [render, setRender] = useState(null);

  localforage.getItem('user')
  .then((user) => {
    if(user != null){
      setRender(
        <div>
          <NavbarUser user={user} />
          <Routes>

            <Route path="/" element={<></>} />
            
            {/* moldes */}
            <Route path="/cadastrareditarmolde" element={<CadEdicaoMoldes user={user} />} />
            <Route path="/buscarmoldes" element={<BuscarMoldes user={user} />} />

            {/* etiquetas */}
            <Route path="/printetiquetas" element={<PrintEtiquetas user={user} />} />

          </Routes>
        </div>
      );
    }else{
      setRender(
        <Routes>
          <Route path="*" element={<Login />} />
        </Routes>
      );
    }
  })
  .catch((error) => {
    setRender(
      <Routes>
        <Route path="*" element={<Login />} />
      </Routes>
    );
  });

  return(
    <BrowserRouter>
      {render}
    </BrowserRouter>
  );
}

export default App
