import { 
    Button, 
    Container, 
    Row, 
    Col, 
    Modal,
    Spinner
} from 'react-bootstrap';
import { FaRegSquareCheck } from "react-icons/fa6";
import { BiError } from 'react-icons/bi';
import PropTypes from 'prop-types';


ModalConfimacao.propTypes = {

    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
    funcPos: PropTypes.func,

    msgModalConfirmarAcao: PropTypes.string

}

ModalSucesso.propTypes = {
    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
    msg: PropTypes.string.isRequired,
    funcPos: PropTypes.func.isRequired
}

ModalErro.propTypes = {
    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
    msg: PropTypes.string.isRequired,
    funcPos: PropTypes.func.isRequired
}

ModalLoad.propTypes = {
    showModal: PropTypes.bool.isRequired
}

export function ModalConfimacao({ showModal, setShowModal, funcPos, msgModalConfirmarAcao }) {

    return(
        <Modal
        show={showModal}
        onHide={() => setShowModal(false) }
        keyboard={false}
        id="modalConfirmarAcao"
        centered
        >
            <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row style={{marginBottom: "5%"}}>
                        <Col style={{textAlign: "center", alignItems: "center"}}><h3>{msgModalConfirmarAcao}</h3></Col>
                    </Row>
                    <Row >

                        <Col>
                            <div className="d-grid gap-2">
                                <Button
                                variant="success"
                                onClick={() => {
                                    funcPos(); 
                                    setShowModal(false);
                                }}
                                >Sim</Button>{' '}
                            </div>
                        </Col>
                        <Col>
                            <div className="d-grid gap-2">
                                <Button
                                variant="danger"
                                onClick={() => {
                                    setShowModal(false);
                                }}
                                >Não</Button>{' '}
                            </div>
                        </Col>

                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );

}

export function ModalSucesso({showModal, setShowModal, msg, funcPos}) {

    return(
        <Modal
        show={showModal}
        onHide={() => { setShowModal(false) } }
        keyboard={false}
        id="modalSucess"
        centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <Container>
                        <Row>
                            <Col>
                                <FaRegSquareCheck  size={50}/>
                            </Col>
                            <Col>
                                <h2>Sucesso</h2> 
                            </Col>
                        </Row>
                    </Container>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row style={{marginBottom: "5%"}}>
                    <Col style={{textAlign: "center", alignItems: "center"}}><h3>{msg}</h3></Col>
                </Row>
                <Row >

                    <Col>
                        <div className="d-grid gap-2">
                            <Button
                            variant="success"
                            onClick={ () => {setShowModal(false); funcPos();} }
                            >OK</Button>{' '}
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
}

export function ModalLoad({showModal}){

    return (

        <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        id="modalLoad"
        centered
        >
            <Modal.Header>
                <Modal.Title> Carregando...</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{justifyContent: "center", justifyItems: "center"}}>
                <Spinner animation="border" role="status" >
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </Modal.Body>
        </Modal>

    );

}

export function ModalErro({showModal, setShowModal, msg, funcPos}) {

    return(

        <Modal
        show={showModal}
        onHide={() => { setShowModal(false); funcPos(); } }
        keyboard={false}
        id="modalErro"
        centered
        >
            <Modal.Header closeButton>
                <Modal.Title><h1><BiError /> Erro !</h1></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {msg}
            </Modal.Body>
        </Modal>

    );

}


