// utils/wrapTextComponent.js

/**
 * Transforma uma string em um componente React com quebras de linha baseadas no número máximo de caracteres por linha.
 * @param {string} text - A string de entrada.
 * @param {number} maxChars - O número máximo de caracteres por linha.
 * @returns {JSX.Element[]} - Um array de elementos React, cada linha como um componente separado.
 */
import React from 'react';

export function wrapTextAsComponent(text, maxChars) {
  if (!text || typeof text !== 'string') return [];
  if (!maxChars || typeof maxChars !== 'number' || maxChars <= 0) return [<span key={0}>{text}</span>];

  const words = text.split(' ');
  let currentLine = '';
  const lines = [];

  words.forEach(word => {
    if ((currentLine + word).length > maxChars) {
      lines.push(currentLine.trim());
      currentLine = word + ' ';
    } else {
      currentLine += word + ' ';
    }
  });

  // Adiciona a última linha.
  lines.push(currentLine.trim());

  // Retorna cada linha como um componente React separado.
  return lines.map((line, index) => (
    <span key={index}>
      {line}
      <br />
    </span>
  ));
}
