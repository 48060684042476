
import { addDays, format } from "date-fns";

export function FormattedDate (dateString){
    const date = new Date(dateString);

    // Formatando a data
    const formattedDate = new Intl.DateTimeFormat('pt-BR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
    }).format(date);

    return formattedDate;
}

export function tratamentoErro(error) {
    if (error.response) {
        const statusCode = error.response.status;
        const statusText = error.response.statusText || 'Erro desconhecido';
        const serverMessage = error.response.data?.message || 'Sem detalhes adicionais';

        // Tratamento específico com base no código de status HTTP
        if (statusCode >= 500) {
            // Erros de servidor (5xx)
            return `Erro no servidor: ${statusCode} - ${statusText}. Estamos enfrentando problemas técnicos. Detalhes: ${serverMessage}`;
        } else if (statusCode >= 400) {
            // Erros do cliente (4xx)
            if (statusCode === 401) {
                return 'Erro de autenticação: acesso não autorizado. Verifique suas credenciais.';
            } else if (statusCode === 403) {
                return 'Erro de permissão: você não tem permissão para acessar este recurso.';
            } else if (statusCode === 404) {
                return 'Recurso não encontrado: o recurso solicitado não pôde ser encontrado no servidor.';
            } else {
                return `Erro na solicitação: ${statusCode} - ${statusText}. Detalhes: ${serverMessage}`;
            }
        } else if (statusCode >= 300) {
            // Redirecionamentos (3xx)
            return `Redirecionamento inesperado: ${statusCode} - ${statusText}. Detalhes: ${serverMessage}`;
        } else {
            // Tratamento genérico para outros códigos de resposta HTTP
            return `Erro desconhecido: ${statusCode} - ${statusText}. Detalhes: ${serverMessage}`;
        }

    } else if (error.request) {
        // Falha de comunicação com o servidor (sem resposta)
        return 'Falha na comunicação com o servidor. Verifique sua conexão de rede ou tente novamente mais tarde.';
    } else {
        // Erro desconhecido ou falha de configuração
        return `Erro inesperado: ${error.message || 'Erro desconhecido. Tente novamente.'}`;
    }
}

export const formatExpiryDate = ({ daysRemaining }) => {
    // Calcula a data atual
    const today = new Date();

    // Adiciona os dias restantes para calcular a data de validade
    const expiryDate = addDays(today, daysRemaining);

    // Formata a data de validade em "dd/MM/yyyy"
    const formattedExpiryDate = format(expiryDate, "dd/MM/yyyy");

    return formattedExpiryDate;
};


export const cmToPx = (cm) => cm * 37.795;

export const formatMoney = (value) => {
    return new Intl.NumberFormat('pt-BR', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(value);
};
