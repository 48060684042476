
import axios from "axios";
import { useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";

import { ModalConfimacao, ModalErro, ModalLoad, ModalSucesso } from "../../components/models/models";
import global from "../../utils/global";
import { MdOutlineAddBox, MdOutlineEdit } from "react-icons/md";
import { VscDiffRemoved } from "react-icons/vsc";
import { AiFillDelete } from "react-icons/ai";

export default function BuscarMoldes ({user}) {

    const [nomeMolde, setNomeMolde] = useState('');
    const [numColunas, setNumColunas] = useState('');

    const [listMoldes, setListMoldes] = useState([]);

    //modal load
    const [showModalLoad, setShowModalLoad] = useState(false);

    //modal erro
    const [showModalErro, setShowModalErro] = useState(false);
    const [msgModalErro, setMsgModalErro] = useState('');
    const [funcPosModalErro, setFuncPosModalErro] = useState(() => () => {});

    //modal sucesso
    const [showModalSucesso, setShowModalSucesso] = useState(false);
    const [msgModalSucesso, setMsgModalSucesso] = useState('');
    const [funcPosModalSucesso, setFuncPosModalSucesso] = useState(() => () => {});

    //modal confirmar acao
    const [showModalConfirmarAcao, setShowModalConfirmarAcao] = useState(false);
    const [msgModalConfirmarAcao, setMsgModalConfirmarAcao] = useState('');
    const [funcPosModalConfirmarAcao, setFuncPosModalConfirmarAcao] = useState(() => () => {});

    const funcBuscar = () => {

        setShowModalLoad(true);

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: global.baseUrl + '/AppEtiqueta/BuscarMoldes?numCol=' + numColunas + '&nome=' + nomeMolde,
            headers: { 
                'ClientKey': user.clientKey,
            }
        };
        
        axios.request(config)
        .then((response) => {
            console.log(response);
            if(response.data.code == 0){
                
                let dados = response.data.content;
                console.log(dados);

                setListMoldes(dados);

            }else{
                setMsgModalErro('Erro ao salvar molde: ' + response.data.msg);
                setShowModalErro(true);
            }
        })
        .catch((error) => {
            setMsgModalErro('Erro ao salvar molde: ' + tratamentoErro(error));
            setShowModalErro(true);
        })
        .finally(() => setShowModalLoad(false));

    }

    return (
        <div style={{marginRight: 50, marginLeft: 50}}>
            <ModalErro
                showModal={showModalErro}
                setShowModal={setShowModalErro}
                msg={msgModalErro}
                funcPos={funcPosModalErro}
            />
            <ModalSucesso
                showModal={showModalSucesso}
                setShowModal={setShowModalSucesso}
                msg={msgModalSucesso}
                funcPos={funcPosModalSucesso}
            />
            <ModalLoad showModal={showModalLoad} />
            <ModalConfimacao
                showModal={showModalConfirmarAcao}
                setShowModal={setShowModalConfirmarAcao}
                funcPos={funcPosModalConfirmarAcao}
                msgModalConfirmarAcao={msgModalConfirmarAcao}
            />
            <Row style={{}}>
                <h2>Buscar moldes</h2>
                <hr />
            </Row>
            <Row style={{marginTop: 10}}>
                <Col>
                    <Form.Label>Nome do molde</Form.Label>
                    <Form.Control
                        type="text"
                        value={nomeMolde}
                        onChange={(event) => setNomeMolde(event.target.value)}
                    />
                </Col>
                <Col>
                    <Form.Label>Numeros colunas: </Form.Label>
                    <Form.Control
                        type={'number'}
                        step={'0.1'}
                        value={numColunas}
                        onChange={(e) => setNumColunas(e.target.value)}
                    />
                </Col>
            </Row>
            <Row style={{marginTop: 15}}>
                <Col>
                    <div className="d-grid gap-2">
                        <Button
                            variant="primary"
                            onClick={funcBuscar}
                        >
                            Buscar
                        </Button>
                    </div>
                </Col>
            </Row>
            <hr />
            {
                listMoldes.length > 0
                ?
                    <Table>
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Altura (cm)</th>
                                <th>Largura (cm)</th>
                                <th>Num Colunas</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listMoldes.map(item => {
                                    return(
                                        <tr key={item.uuidMoldeEtiqueta}>
                                            <th>{item.nomeMolde}</th>
                                            <th>{item.altura}</th>
                                            <th>{item.largura}</th>
                                            <th>{item.numColunas}</th>
                                            <th >
                                                    <Button
                                                        variant="primary"
                                                        onClick={() => window.location.href = "/cadastrareditarmolde?id=" + item.uuidMoldeEtiqueta}
                                                        style={{marginLeft: 2}}
                                                    >
                                                        <MdOutlineEdit size={25} />
                                                    </Button>
                                                    <Button
                                                        variant="primary"
                                                        onClick={() => {

                                                            setFuncPosModalConfirmarAcao(
                                                                () => () => {

                                                                    let config = {
                                                                        method: 'get',
                                                                        maxBodyLength: Infinity,
                                                                        url: global.baseUrl + '/AppEtiqueta/ExcluirMolde?id=' + item.uuidMoldeEtiqueta,
                                                                        headers: { 
                                                                            'ClientKey': user.clientKey,
                                                                        }
                                                                    };
                                                                      
                                                                    axios.request(config)
                                                                    .then((response) => {
                                                                        console.log(response);
                                                                        if(response.data.code == 0){
                                                                            
                                                                            setMsgModalSucesso("Molde excluido com sucesso!");
                                                                            setShowModalSucesso(true);
                                                            
                                                                            funcBuscar();
        
                                                                        }else{
                                                                            setMsgModalErro('Erro ao excluir molde: ' + response.data.msg);
                                                                            setShowModalErro(true);
                                                                        }
                                                                    })
                                                                    .catch((error) => {
                                                                        setMsgModalErro('Erro ao excluir molde: ' + tratamentoErro(error));
                                                                        setShowModalErro(true);
                                                                    })
                                                                    .finally(() => setShowModalLoad(false));

                                                                }
                                                            );
                                                            setMsgModalConfirmarAcao("Tem certeza que deseja excluir o molde?");
                                                            setShowModalConfirmarAcao(true);


                                                        }}
                                                        style={{marginLeft: 2}}
                                                    >
                                                        <AiFillDelete size={25} />
                                                    </Button>
                                            </th>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </Table>
                :
                    <Row>
                        Não há registros
                    </Row>
            }

        </div>
    );
};
