
import axios from "axios";
import { useReactToPrint, ReactToPrint } from "react-to-print";
import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row, Table, Pagination } from "react-bootstrap";

import global from "../../utils/global";
import { tratamentoErro, formatExpiryDate, cmToPx, formatMoney } from "../../utils/utils";
import ContainerPrintPreview from "../../components/dragDrop/ContainerPrintPreview";
import { ModalErro, ModalLoad, ModalSucesso } from "../../components/models/models";
import Barcode from "react-barcode";
import { wrapTextAsComponent } from "../../components/dragDrop/wrapTextAsComponent";

export default function PrintEtiquetas({user}) {

    const [filialSelecionada, setFilialSelecionada] = useState(user.idEmpresaDefault);
    const [moldeSelecionado, setMoldeSelecionado] = useState('');
    const [idSku, setIdSku] = useState('');
    const [nomeProdutos, setNomeProdutos] = useState('');
    const [ean, setEan] = useState('');
    const [espacamentoLinhaPreview, setEspacamentoLinhaPreview] = useState(0);
    const [margemLinhaPreview, setMargemLinhaPreview] = useState(0);

    const [listMoldes, setListMoldes] = useState([]);

    const [listProdutos, setListProdutos] = useState([]);
    const [contaProdutos, setContaProdutos] = useState(0);

    const [listFornecedores, setListFornecedores] = useState([]);
    const [fornecedorSelecionado, setFornecedorSelecionado] = useState('');
    const [listDepartamento, setListDepartamento] = useState([]);
    const [departamentoSelecionado, setDepartamentoSelecionado] = useState('');
    const [listMarcas, setListMarcas] = useState([]);
    const [marcaSelecionada, setMarcaSelecionada] = useState('');
    const [listTabelaPreco, setListTabelaPreco] = useState([]);
    const [tabelaPrecoSelecionada, setTabelaPrecoSelecionada] = useState('');

    const [produtosSelecionados, setProdutosSelecionados] = useState([]);
    
    // Estado para a página atual e itens por página
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Número de itens por página

    // Calcular os produtos a serem exibidos na página atual
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentProducts = listProdutos.slice(indexOfFirstItem, indexOfLastItem);

    // Número total de páginas
    const totalPages = Math.ceil(listProdutos.length / itemsPerPage);
    
    //ref
    var componentRef = useRef();

    // const handlePrint = useReactToPrint({
    //     content: () => componentRef.current
    // });

    //modal load
    const [showModalLoad, setShowModalLoad] = useState(false);

    //modal erro
    const [showModalErro, setShowModalErro] = useState(false);
    const [msgModalErro, setMsgModalErro] = useState('');
    const [funcPosModalErro, setFuncPosModalErro] = useState(() => () => {});

    //modal sucesso
    const [showModalSucesso, setShowModalSucesso] = useState(false);
    const [msgModalSucesso, setMsgModalSucesso] = useState('');
    const [funcPosModalSucesso, setFuncPosModalSucesso] = useState(() => () => {});                              

    useEffect(() => {

        //buscar filtros do buscar produtos
        const func = async () => {
            if(filialSelecionada != '' && filialSelecionada != null){

                setShowModalLoad(true);
                
                await axios.request({
                    method: 'get',
                    maxBodyLength: Infinity,
                    url: global.baseUrl + '/AppEtiqueta/ListaDepartamento?idFilial=' + filialSelecionada,
                    headers: { 
                        'ClientKey': user.clientKey,
                    }
                })
                .then((response) => {
                    if(response.data.code == 0){
                    
                        let dados = response.data.content;
                        setListDepartamento(dados);
        
                    }else{
                        setMsgModalErro('Erro ao listar departamentos: ' + response.data.msg);
                        setShowModalErro(true);
                    }
                })
                .catch((error) => {
                    setMsgModalErro('Erro ao listar departamentos: ' + tratamentoErro(error));
                    setShowModalErro(true);
                });

                await axios.request({
                    method: 'get',
                    maxBodyLength: Infinity,
                    url: global.baseUrl + '/AppEtiqueta/ListaFornecedor?idFilial=' + filialSelecionada,
                    headers: { 
                        'ClientKey': user.clientKey,
                    }
                })
                .then((response) => {
                    if(response.data.code == 0){
                    
                        let dados = response.data.content;
                        setListFornecedores(dados);
        
                    }else{
                        setMsgModalErro('Erro ao listar fornecedores: ' + response.data.msg);
                        setShowModalErro(true);
                    }
                })
                .catch((error) => {
                    setMsgModalErro('Erro ao listar fornecedores: ' + tratamentoErro(error));
                    setShowModalErro(true);
                });

                await axios.request({
                    method: 'get',
                    maxBodyLength: Infinity,
                    url: global.baseUrl + '/AppEtiqueta/ListaMarca?idFilial=' + filialSelecionada,
                    headers: { 
                        'ClientKey': user.clientKey,
                    }
                })
                .then((response) => {
                    if(response.data.code == 0){
                    
                        let dados = response.data.content;
                        setListMarcas(dados);
        
                    }else{
                        setMsgModalErro('Erro ao listar marcas: ' + response.data.msg);
                        setShowModalErro(true);
                    }
                })
                .catch((error) => {
                    setMsgModalErro('Erro ao listar marcas: ' + tratamentoErro(error));
                    setShowModalErro(true);
                });

                await axios.request({
                    method: 'get',
                    maxBodyLength: Infinity,
                    url: global.baseUrl + '/AppEtiqueta/ListaTabelaPreco?idFilial=' + filialSelecionada,
                    headers: { 
                        'ClientKey': user.clientKey,
                    }
                })
                .then((response) => {
                    if(response.data.code == 0){
                    
                        let dados = response.data.content;
                        setListTabelaPreco(dados);
        
                    }else{
                        setMsgModalErro('Erro ao listar tabela preço: ' + response.data.msg);
                        setShowModalErro(true);
                    }
                })
                .catch((error) => {
                    setMsgModalErro('Erro ao listar tabela preço: ' + tratamentoErro(error));
                    setShowModalErro(true);
                });
    
                setShowModalLoad(false)
    
            }
        }

        func();

    }, [filialSelecionada]);

    useEffect(() => {

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: global.baseUrl + '/AppEtiqueta/BuscarMoldes',
            headers: { 
                'ClientKey': user.clientKey,
            }
        };
        
        axios.request(config)
        .then((response) => {
            if(response.data.code == 0){
                
                let dados = response.data.content;
                setListMoldes(dados);

            }else{
                setMsgModalErro('Erro ao salvar molde: ' + response.data.msg);
                setShowModalErro(true);
            }
        })
        .catch((error) => {
            setMsgModalErro('Erro ao salvar molde: ' + tratamentoErro(error));
            setShowModalErro(true);
        })
        .finally(() => setShowModalLoad(false));

    }, []);

    const funcBuscarProdutos = useCallback(() => {

        if(filialSelecionada == ""){
            setMsgModalErro("A filial deve ser selecionada");
            setShowModalErro(true);

            return;
        }

        setShowModalLoad(true);

        let url = global.baseUrl + '/AppEtiqueta/ListaProdutos?idFilial=' + filialSelecionada;

        if(departamentoSelecionado != ''){
            url += '&idDepartamento=' + departamentoSelecionado;
        }
        if(fornecedorSelecionado != ''){
            url += '&idFonecedor=' + fornecedorSelecionado;
        }
        if(marcaSelecionada != ''){
            url += '&idMarca=' + marcaSelecionada;
        }
        if(tabelaPrecoSelecionada != ''){
            url += '&idTabelaPreco=' + tabelaPrecoSelecionada;
        }
        if(idSku != ''){
            url += '&idSku=' + idSku;
        }
        if(nomeProdutos != ''){
            url += '&nomeProduto=' + nomeProdutos;
        }
        if(ean != ''){
            url += '&ean=' + ean;
        }

        axios.request({
            method: 'get',
            maxBodyLength: Infinity,
            url: url,
            headers: { 
                'ClientKey': user.clientKey,
            }
        })
        .then((response) => {
            console.log(response);
            if(response.data.code == 0){
                
                let dados = response.data.content;
                console.log("Listar Produtos: ", dados);

                setListProdutos(dados);
                setContaProdutos(dados.length);

            }else{
                setMsgModalErro('Erro ao buscar produtos: ' + response.data.msg);
                setShowModalErro(true);
            }
        })
        .catch((error) => {
            console.log(error);
            setMsgModalErro('Erro ao buscar produtos: ' + tratamentoErro(error));
            setShowModalErro(true);
        })
        .finally(() => setShowModalLoad(false));
    });

    const handleQuantityChange = (codUnicoProduto, newQuantity) => {
        setProdutosSelecionados(prevState =>
            prevState.map(produto =>
                produto.codUnicoProduto === codUnicoProduto
                    ? { ...produto, quantidade: newQuantity }
                    : produto
            )
        );
    };

    // Renderização do componente de paginação
    const renderPagination = () => {
        const paginationItems = [];
        const maxPagesToShow = 3; // Número máximo de botões de página visíveis ao mesmo tempo
        const ellipsis = <Pagination.Ellipsis disabled key="ellipsis" />;
    
        const createPageItem = (page) => (
            <Pagination.Item
                key={page}
                active={page === currentPage}
                onClick={() => setCurrentPage(page)}
            >
                {page}
            </Pagination.Item>
        );
    
        // Adicionar botão "Anterior"
        paginationItems.push(
            <Pagination.Prev
                key="prev"
                disabled={currentPage === 1}
                onClick={() => setCurrentPage(currentPage - 1)}
            />
        );
    
        // Lógica para exibir os números das páginas
        if (totalPages <= maxPagesToShow) {
            // Caso tenha poucas páginas, mostra todas
            for (let i = 1; i <= totalPages; i++) {
                paginationItems.push(createPageItem(i));
            }
        } else {
            // Mostrar apenas páginas próximas e extremos
            const startPage = Math.max(1, currentPage - 2);
            const endPage = Math.min(totalPages, currentPage + 2);
    
            // Sempre mostrar a primeira página
            if (startPage > 1) {
                paginationItems.push(createPageItem(1));
                if (startPage > 2) paginationItems.push(ellipsis); // Adiciona "..."
            }
    
            // Mostrar páginas próximas
            for (let i = startPage; i <= endPage; i++) {
                paginationItems.push(createPageItem(i));
            }
    
            // Sempre mostrar a última página
            if (endPage < totalPages) {
                if (endPage < totalPages - 1) paginationItems.push(ellipsis); // Adiciona "..."
                paginationItems.push(createPageItem(totalPages));
            }
        }
    
        // Adicionar botão "Próximo"
        paginationItems.push(
            <Pagination.Next
                key="next"
                disabled={currentPage === totalPages}
                onClick={() => setCurrentPage(currentPage + 1)}
            />
        );
    
        return <Pagination style={{ justifyContent: 'center' }}>{paginationItems}</Pagination>;
    };
    

    return(
        <div style={{marginRight: 50, marginLeft: 50}}>
            <ModalErro
                showModal={showModalErro}
                setShowModal={setShowModalErro}
                msg={msgModalErro}
                funcPos={funcPosModalErro}
            />
            <ModalSucesso
                showModal={showModalSucesso}
                setShowModal={setShowModalSucesso}
                msg={msgModalSucesso}
                funcPos={funcPosModalSucesso}
            />
            <ModalLoad showModal={showModalLoad} />
            <Row style={{}}>
                <h2>Imprimir Etiquetas</h2>
                <hr />
            </Row>
            <Row>
                <Form.Select
                    value={filialSelecionada}
                    onChange={(e) => setFilialSelecionada(e.target.value)}
                >
                    {
                        user.empresas.map((emp, index) => 
                            <option value={emp.idEmpresa} key={index} >{emp.nomeFantasia}</option>
                        )
                    }
                </Form.Select>
            </Row>
            <Row style={{marginTop: 15}}>
                <Form.Select
                    value={moldeSelecionado}
                    onChange={(e) => setMoldeSelecionado(e.target.value)}
                >
                    <option value={''}>Selecione o molde</option>
                    {
                        listMoldes.map((molde, index) => 
                            <option value={molde.uuidMoldeEtiqueta} key={index} >{molde.nomeMolde}</option>
                        )
                    }
                </Form.Select>
            </Row>
            <Row style={{marginTop: 15}}>
                <hr />
            </Row>
            {
                filialSelecionada != ''
                &&
                <div>
                    <Row style={{marginBottom: 15}}>
                        <Col style={{borderRight: '1px solid black'}}>
                            <Row style={{}}>
                                <h4>Produtos:</h4>
                                <hr />
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label>Nome produto: </Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={nomeProdutos}
                                        onChange={(event) => setNomeProdutos(event.target.value)}
                                    />
                                </Col>
                                <Col>
                                    <Form.Label>Ean: </Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={ean}
                                        onChange={(event) => setEan(event.target.value)}
                                    />
                                </Col>
                                <Col>
                                    <Form.Label>SKU: </Form.Label>
                                    <Form.Control
                                        type={'number'}
                                        step={'0.1'}
                                        value={idSku}
                                        onChange={(e) => setIdSku(e.target.value)}
                                    />
                                </Col>
                            </Row>
                            <Row style={{marginTop: 15}}>
                                <Col>
                                    <Form.Label>Fornecedor: </Form.Label>
                                    <Form.Select
                                        value={fornecedorSelecionado}
                                        onChange={(e) => setFornecedorSelecionado(e.target.value)}
                                    >
                                        <option value={''}>Selecione</option>
                                        {
                                            listFornecedores.map((item, index) => 
                                                <option value={item.idPessoa} key={index} >{item.nome}</option>
                                            )
                                        }
                                    </Form.Select>
                                </Col>
                                <Col>
                                    <Form.Label>Departamento: </Form.Label>
                                    <Form.Select
                                        value={departamentoSelecionado}
                                        onChange={(e) => setDepartamentoSelecionado(e.target.value)}
                                    >
                                        <option value={''}>Selecione</option>
                                        {
                                            listDepartamento.map((item, index) => 
                                                <option value={item.idDepartamento} key={index} >{item.nomeDepartamento}</option>
                                            )
                                        }
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Row style={{marginTop: 15}}>
                                <Col>
                                    <Form.Label>Marcas: </Form.Label>
                                    <Form.Select
                                        value={marcaSelecionada}
                                        onChange={(e) => setMarcaSelecionada(e.target.value)}
                                    >
                                        <option value={''}>Selecione</option>
                                        {
                                            listMarcas.map((item, index) => 
                                                <option value={item.idMarca} key={index} >{item.marca}</option>
                                            )
                                        }
                                    </Form.Select>
                                </Col>
                                <Col>
                                    <Form.Label>Tabela preço: </Form.Label>
                                    <Form.Select
                                        value={tabelaPrecoSelecionada}
                                        onChange={(e) => setTabelaPrecoSelecionada(e.target.value)}
                                    >
                                        <option value={''}>Selecione</option>
                                        {
                                            listTabelaPreco.map((item, index) => 
                                                <option value={item.idTabelaPreco} key={index} >{item.nomeTabela}</option>
                                            )
                                        }
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Row style={{marginTop: 15}}>
                                <div className="d-grid gap-2">
                                    <Button
                                        variant="primary"
                                        onClick={funcBuscarProdutos}
                                    >
                                        Buscar Produtos
                                    </Button>
                                </div>
                            </Row>
                            {
                                contaProdutos > 0 &&
                                <Row>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>EAN</th>
                                                <th>Nome</th>
                                                <th>Validade</th>
                                                <th>Preço</th>
                                                <th>Quant</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {listProdutos.slice(((currentPage * itemsPerPage) - (itemsPerPage)), ((currentPage * itemsPerPage) - 1)).map(item => {
                                            const isSelected = produtosSelecionados.some(produto => produto.codUnicoProduto === item.codUnicoProduto);
                                            const selectedProduct = produtosSelecionados.find(produto => produto.codUnicoProduto === item.codUnicoProduto);

                                            return (
                                                <tr key={item.codUnicoProduto}>
                                                    <td>
                                                        <Form.Check
                                                            type="checkbox"
                                                            checked={isSelected}
                                                            onChange={(e) =>{
                                                                if (e.target.checked) {
                                                                    // Adiciona o produto com quantidade inicial de 1
                                                                    setProdutosSelecionados(prevState => [...prevState, { ...item, quantidade: 1 }]);
                                                                } else {
                                                                    // Remove o produto pelo id
                                                                    setProdutosSelecionados(prevState => prevState.filter(produto => produto.codUnicoProduto !== item.codUnicoProduto));
                                                                }
                                                            }}
                                                        />
                                                    </td>
                                                    <td>{item.codigoBarras}</td>
                                                    <td>{item.nomeProdutoEtiqueta}</td>
                                                    <td>{item.prazoDiasValidade === 0 ? "Validade não informada" : formatExpiryDate({ daysRemaining: item.prazoDiasValidade })}</td>
                                                    <td>{item.preco == null ? "Preço não informado" : "R$ " + formatMoney(item.preco)}</td>
                                                    <td>
                                                        {isSelected 
                                                            ? (
                                                            <Form.Control
                                                                type="number"
                                                                value={selectedProduct.quantidade}
                                                                min="1"
                                                                step={1}
                                                                onChange={(e) => handleQuantityChange(item.codUnicoProduto, parseInt(e.target.value, 10))}
                                                            />)
                                                            :
                                                            <div>Não selecionado</div>
                                                        }
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                        </tbody>
                                    </Table>
                                </Row>
                            }
                            <Row className="justify-content-center mt-3">
                                {
                                    contaProdutos > 0 && 
                                    renderPagination()
                                }
                            </Row>
                        </Col>
                        <Col
                            md={5}
                        >
                            <Row style={{}}>
                                <h4>Preview da impressão:</h4>
                                <hr />
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label>Espaçamento (cm) entre as linhas: {espacamentoLinhaPreview} </Form.Label>
                                    <Form.Range value={espacamentoLinhaPreview} onChange={(e) => {setEspacamentoLinhaPreview(e.target.value);}} step={0.001} max={0.5} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label>Margem esquerda (cm) nas linhas: {margemLinhaPreview} </Form.Label>
                                    <Form.Range value={margemLinhaPreview} onChange={(e) => {setMargemLinhaPreview(e.target.value);}} step={0.01} max={10} />
                                </Col>
                            </Row>
                            <div className="d-grid gap-2">
                                {/* <Button
                                onClick={handlePrint}
                                variant="primary"
                                >Imprimir</Button> */}
                                <ReactToPrint
                                    content={() => componentRef}
                                    trigger={() => (
                                        <div className="d-grid gap-2">
                                            <Button
                                            variant="primary"
                                            >
                                                Imprimir
                                            </Button>
                                        </div>
                                    )}
                                    
                                />

                            </div>
                            <hr/>
                            
                            <div ref={(refer) => (componentRef = refer)} className="no-break" style={{ marginLeft: `${margemLinhaPreview}cm`}}>
                                {
                                    moldeSelecionado != '' && produtosSelecionados.length > 0 
                                    ?
                                        produtosSelecionados.flatMap(
                                            item => {

                                                const dadosMoldes = listMoldes.find(molde => molde.uuidMoldeEtiqueta == moldeSelecionado);
                                                const box = {
                                                    a: { 
                                                        top: dadosMoldes.yNomeProduto, 
                                                        left: dadosMoldes.xNomeProduto, 
                                                        text: wrapTextAsComponent((dadosMoldes.textPreNomeProduto + item.nomeProdutoEtiqueta).slice(0, dadosMoldes.limiteCharTotal), parseInt(dadosMoldes.limiteCharPorLinha)), 
                                                        fontSize: dadosMoldes.fontSizeNomeProduto, 
                                                        fontWeight: dadosMoldes.fontWreigthNomeProduto, 
                                                        fontFamily: dadosMoldes.fontNomeProduto 
                                                    },//nome do produto
                                                    b: { 
                                                        top: dadosMoldes.yPreco, 
                                                        left: dadosMoldes.xPreco, 
                                                        text: dadosMoldes.habilitaPreco && (item.preco != null && item.preco != undefined && item.preco != 0) ? (dadosMoldes.textPrePreco + formatMoney(item.preco)) : "", 
                                                        fontSize: dadosMoldes.fontSizePreco, 
                                                        fontWeight: dadosMoldes.fontWreigthPreco, 
                                                        fontFamily: dadosMoldes.fontPreco 
                                                    },//Preço
                                                    c: { 
                                                        top: dadosMoldes.yDiasParaVencimento, 
                                                        left: dadosMoldes.xDiasParaVencimento, 
                                                        text: dadosMoldes.habilitaDataValidade ? ((item.prazoDiasValidade === 0 ? "" : dadosMoldes.textPreDataValidade + formatExpiryDate({ daysRemaining: item.prazoDiasValidade }))) : "", 
                                                        fontSize: dadosMoldes.fontSizediasParaVencimento, 
                                                        fontWeight: dadosMoldes.fontWreigthDiasVencimento, 
                                                        fontFamily: dadosMoldes.fontDiasParaVencimento
                                                    },//data de validade
                                                    d: {
                                                        top: dadosMoldes.yCodigoBarra, 
                                                        left: dadosMoldes.xCodigoBarra, 
                                                        text: dadosMoldes.habilitaCodBarras ? ( 
                                                            item.codigoBarras != null && item.codigoBarras != undefined && item.codigoBarras != "" && 
                                                                <Barcode
                                                                    value={item.codigoBarras}
                                                                    width={cmToPx(dadosMoldes.larguraCodBarras) / 100}
                                                                    height={cmToPx(dadosMoldes.alturaCodBarras)}
                                                                    margin={5}
                                                                    displayValue={dadosMoldes.exibirCodigoEanNumerico}
                                                                    format={dadosMoldes.formatoCodBarras}
                                                                />
                                                            )
                                                            : null
                                                    }
                                                }
                                                const rows = [];

                                                /* Preview */
                                                // 
                                                for (let i = 0; i < item.quantidade; i++) {
                                                    rows.push(
                                                        <ContainerPrintPreview 
                                                            habilitaBorda={false} 
                                                            width={dadosMoldes.largura} 
                                                            height={dadosMoldes.altura} 
                                                            boxes={box} 
                                                            num_col={dadosMoldes.numColunas} 
                                                            espacamento={dadosMoldes.espacamento} 
                                                            key={i}
                                                            styles={{
                                                                marginTop: `${espacamentoLinhaPreview}cm`,
                                                            }}
                                                        />
                                                    );
                                                }

                                                return rows;

                                            }
                                        )
                                    :
                                        <p style={{textAlign: "center"}}>Selecione um molde e um produto</p>
                                }
                            </div>
                        </Col>
                    </Row>
                    


                </div>
            }
        </div>
    );
}
