import localforage from "localforage";
import { Col, Container, Nav, Navbar, NavDropdown, Row } from "react-bootstrap";
import { GrPowerShutdown } from "react-icons/gr";
import logo from '../assets/icone_app_etiqueta.png';
import { useEffect } from "react";

export default function NavbarUser({user}) {

    return (
        <div>
            <Navbar expand="lg" style={{ backgroundColor: "#dddddd", margin: 10, borderRadius: 10}}>
                <Navbar.Brand href="/" style={{margin: 10}}>
                    <img  src={logo} style={{width: 50, height: 50}}/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbar-nav" />
                <Navbar.Collapse id="navbar-nav">
                    <Nav className="me-auto">
                        {/* Dropdown de etiqueta */}
                        <NavDropdown title="Molde etiqueta" id="nav-dropdown-moldes-etiqueta">
                            <NavDropdown.Item href="/cadastrareditarmolde">Cadastrar molde</NavDropdown.Item> 
                            <NavDropdown.Item href="/buscarmoldes">Buscar molde</NavDropdown.Item> 
                        </NavDropdown>
                        <Nav.Item>
                            <Nav.Link href="/printetiquetas">Imprimir etiquetas</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Nav className="ms-auto" style={{marginRight: 10}}>
                        <span style={{fontSize: 10}}>
                            <Row>
                                {(user.nomeOrganizacao == null || user.nomeOrganizacao == undefined ? "" : user.nomeOrganizacao ) + " - " + user.idOrganizacao}
                            </Row>
                            <Row>
                                {user.nomeFuncionario}
                            </Row>
                            <Row>
                                {user.login}
                            </Row>
                        </span>
                    </Nav>
                    <Nav className="ms-auto" style={{marginRight: 10}}>
                        <Nav.Item>
                            <Nav.Link onClick={() => {
                                localforage.clear();
                                window.location.href = '/login';
                            }}>
                                <GrPowerShutdown color="red" size={12} title="Sair" style={{ marginRight: 5 }}/>
                                <span style={{ textAlign: 'end', color: "red", alignItems: 'flex-end' }}>Sair</span>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
}