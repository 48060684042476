import { memo, useEffect } from 'react'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'

function getStyles(left, top, isDragging) {
  const transform = `translate3d(${left}px, ${top}px, 0)`
  return {
    position: 'absolute',
    transform,
    WebkitTransform: transform,
    // IE fallback: hide the real node using CSS when dragging
    // because IE will ignore our custom "empty image" drag preview.
    opacity: isDragging ? 0 : 1,
    height: isDragging ? 0 : '',
    pointerEvents: isDragging ? 'none' : 'auto', // Impede eventos durante o arraste
  }
}
export const Box = memo(function Box(props) {
  const { id, text, left, top, fontSize, fontWeight, fontFamily } = props
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: 'box',
      item: { id, left, top, text },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [id, left, top, text],
  )
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [])
  if (isDragging) {
    return <div ref={drag} />
  }
  return (
    <div
      ref={drag}
      style={getStyles(left, top, isDragging)}
      role="DraggableBox"
    >
      <div style={{ 
        fontSize: `${fontSize}pt`, 
        fontWeight: `${fontWeight}`, 
        fontFamily: `${fontFamily}`, 
        textAlign: 'center' 
      }} >{text}</div>
    </div>
  )
})
